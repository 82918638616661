import { takeEvery, put, all, call } from 'redux-saga/effects'
import actionTypes from './actionTypes'
import { request } from '../../utils/request'
import { alert } from '../../utils/alert'
import { actionFetchPesertaUjianEnd, actionFetchPesertaUjianStart, actionFetchPesertaUjianSuccess } from './actions'

function* sagaFetchPesertaUjian(params) {
  yield put(actionFetchPesertaUjianStart())

  const { data: { search, page, sort, perPage } } = params
  const header = {
    "Content-Type": "application/json"
  }

  try {
    let sortParam = ""
    if (sort?.by) {
      sortParam += "&sortBy=" + sort?.by
    }
    if (sort?.type) {
      sortParam += "&sortType=" + sort?.type
    }

    let perPageParam = perPage ? "&perPage=" + perPage : ""

    let pageParam = page ? "?page=" + page : "?page=1"

    let searchParam = search ? "&search=" + search : ""

    let url = `${process.env.REACT_APP_SERVICE_PESERTA_UJIAN}${pageParam}${searchParam}${sortParam}${perPageParam}`
    const res = yield call(request, url, {
      method: 'GET',
      headers: header
    })

    if (res?.success === true) {
      let data = {
        list: res?.result?.data,
        page: res?.result?.current_page,
        pageUrl: {
          first_page_url: res?.result?.first_page_url,
          links: res?.result?.links,
          last_page_url: res?.result?.last_page_url
        },
        last_page: res?.result?.last_page,
        from: res?.result?.from,
        to: res?.result?.to,
        total: res?.result?.total
      }
      yield put(actionFetchPesertaUjianSuccess(data))
    } else {
      alert({ title: "Gagal", html: res?.message })
    }
  } catch (err) {
    alert({ title: "Gagal" })
  }

  yield put(actionFetchPesertaUjianEnd())
}

function* pesertaUjianSaga() {
  yield all([
    takeEvery(actionTypes.FETCH_PESERTA_UJIAN, sagaFetchPesertaUjian)
  ])
}

export default pesertaUjianSaga