import Immutable from 'seamless-immutable'

const FETCH_PESERTA_UJIAN = 'FETCH_PESERTA_UJIAN'
const FETCH_PESERTA_UJIAN_START = 'FETCH_PESERTA_UJIAN_START'
const FETCH_PESERTA_UJIAN_END = 'FETCH_PESERTA_UJIAN_END'
const FETCH_PESERTA_UJIAN_SUCCESS = 'FETCH_PESERTA_UJIAN_SUCCESS'
const FETCH_PESERTA_UJIAN_FAILED = 'FETCH_PESERTA_UJIAN_FAILED'

const actionTypes = Immutable({
  FETCH_PESERTA_UJIAN,
  FETCH_PESERTA_UJIAN_START,
  FETCH_PESERTA_UJIAN_END,
  FETCH_PESERTA_UJIAN_SUCCESS,
  FETCH_PESERTA_UJIAN_FAILED
})

export default actionTypes
