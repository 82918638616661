import createSagaMiddleware from 'redux-saga'
import rootReducer from './reducers';
import rootSaga from './sagas';
import { configureStore } from "@reduxjs/toolkit"
import { persistStore, persistReducer } from "redux-persist";
import storage from 'redux-persist/lib/storage'
import { encryptTransform } from 'redux-persist-transform-encrypt'

const config = {
    key: 'landing',
    storage: storage,
    transforms: [
        encryptTransform({
            secretKey: process.env.REACT_APP_REDUX_KEY
        }),
    ],
}
const middleware = []
const sagaMiddleware = createSagaMiddleware()
middleware.push(sagaMiddleware)
const reducers = persistReducer(config, rootReducer)
const enhancers = [...middleware]
const persistConfig = { enhancers }
const store = configureStore({
    reducer: reducers,
    middleware: enhancers
})
sagaMiddleware.run(rootSaga)
const persistor = persistStore(store, persistConfig)

export { store }
export { persistor }