import React from 'react'
import { Route, Switch } from 'react-router-dom'

import ErrorLayout from "./components/Layout/ErrorLayout"
import Page404 from './pages/Error/Page404'
import Beranda from './pages/Beranda'
import LandingLayout from './components/Layout/LandingLayout'

import HasilSeleksi from './pages/HasilSeleksi'

const App = ({ childProps }) => {
  return (
    <Switch>
      <LandingRoute exact path={process.env.REACT_APP_SUBDIR + "/"} component={Beranda} props={childProps} />
      <LandingRoute path={process.env.REACT_APP_SUBDIR + "/hasil-seleksi"} component={HasilSeleksi} props={childProps} />

      <ErrorRoute path='*' exact={true} component={Page404} />
    </Switch>
  )
}

const ErrorRoute = ({ component: Component, location, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => (
        <ErrorLayout>
          <Component {...props} />
        </ErrorLayout>
      )}
    />
  )
}

const LandingRoute = ({ component: Component, location, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => (
        <LandingLayout>
          <Component {...props} />
        </LandingLayout>
      )}
    />
  )
}

export default App