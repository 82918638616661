import React, { useEffect, useState } from "react"
import IlsBeranda from "../../assets/img/illustrations/grate.png"
import HeadTable from "../../components/Table/HeadTable"
import Pagination from "../../components/Table/Pagination"
import LimitTable from "../../components/Table/LimitTable"
import SearchTable from "../../components/Table/SearchTable"
import { changeUrl } from "../../utils/url"

const collectionData = [
    "Peserta 1"
]

const HasilSeleksi = (props) => {
    const [index, setIndex] = useState({
        total: collectionData?.length,
        list: null
    })
    const [sort, setSort] = useState({
        by: null,
        type: null
    })
    const [search, setSearch] = useState("")
    const [searchValue, setSearchValue] = useState("")
    const [page, setPage] = useState(1)
    const [perPage, setPerPage] = useState("")

    useEffect(() => {
        fetchData()
    }, [])

    const actionFetchPesertaUjian = (prop) => {
        if (prop?.search) {
            let query = prop?.search.toLowerCase()
            index['list'] = collectionData?.filter(item => item.toLowerCase().indexOf(query) >= 0)
        } else {
            index['list'] = collectionData
        }

        if (sort?.type === "desc") {
            index?.list?.sort().reverse()
        } else {
            index?.list?.sort()
        }

        setIndex(index)
    }

    useEffect(() => {
        let queryParams = {}
        if (page) {
            queryParams['page'] = page
        }
        if (search) {
            queryParams['search'] = search
        }
        if (sort?.by) {
            queryParams['sortBy'] = sort?.by
        }
        if (sort?.type) {
            queryParams['sortType'] = sort?.type
        }
        if (perPage) {
            queryParams['perPage'] = perPage
        }

        let query = new URLSearchParams(queryParams).toString()

        changeUrl("?" + query)
    }, [page, search, sort?.by, sort?.type, perPage])

    const fetchData = () => {
        const query = new URLSearchParams(props.location.search)

        if (query.get('page')) {
            setPage(parseInt(query.get('page')))
        } else {
            setPage(1)
        }

        if (query.get('search')) {
            setSearch(query.get('search'))
            setSearchValue(query.get('search'))
        }

        if (query.get('perPage')) {
            setPerPage(parseInt(query.get('perPage')))
        } else {
            setPerPage(10)
        }

        if (query.get('sortBy') || query.get('sortType')) {
            setSort({
                by: query.get('sortBy') || null,
                type: query.get('sortType') || null,
            })
        }

        actionFetchPesertaUjian({
            search: query.get('search') || "",
            page: parseInt(query.get('page')) || 1,
            sort: {
                by: query.get('sortBy') || null,
                type: query.get('sortType') || null,
            },
            perPage: parseInt(query.get('perPage')) || 10
        })
    }

    const sortData = (field, e) => {
        let sortType = sort?.type === "desc" ? "asc" : "desc"
        setSort({
            by: field,
            type: sortType
        })
        actionFetchPesertaUjian({
            search,
            page,
            sort: {
                by: field,
                type: sortType
            },
            perPage
        })
        e.preventDefault()
    }

    const onSearch = (e) => {
        setSearch(searchValue)
        actionFetchPesertaUjian({
            search: searchValue,
            page,
            sort,
            perPage
        })
        e.preventDefault()
    }

    const onChangeSearch = (val) => {
        setSearchValue(val)
    }

    const pageData = (val, e) => {
        setPage(val)
        actionFetchPesertaUjian({
            search,
            page: val,
            sort,
            perPage
        })
        e.preventDefault()
    }

    const changePerPage = (val) => {
        setPerPage(val)
        actionFetchPesertaUjian({
            search,
            page,
            sort,
            perPage: val
        })
    }

    return <React.Fragment>
        <div className="container-xxl flex-grow-1 container-p-y" style={{
            width: "100%",
            maxWidth: "1252px"
        }}>
            <div className="card mt-2">
                <div className="d-flex align-items-end row">
                    <div className="col-xl-8">
                        <div className="card-body">
                            <h5 className="card-title text-primary">Hasil Seleksi Administrasi</h5>
                            <p className="mb-0">Selamat kepada {index?.total > 0 ? <strong className="text-primary">{index?.total}</strong> : null} peserta yang lolos seleksi ujian! 🎉</p>
                        </div>
                    </div>
                    <div className="col-xl-4 text-center text-sm-left">
                        <div className="card-body p-0">
                            <img src={IlsBeranda} height="100" alt="View Badge User" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="card mt-3">
                <div className="card-body py-2">
                    <marquee><small className="text-muted text-uppercase">WASPADA TERHADAP PENIPUAN, SELURUH TAHAPAN PROSES SELEKSI INI TIDAK DIPUNGUT BIAYA APAPUN</small></marquee>
                </div>
            </div>

            <div className="card mt-3">
                <div className="card-body">
                    <div className="row">
                        <div className="col-lg-8 order-1 order-md-0">
                            {
                                index?.total > 0 ?
                                    <p className="mt-1">Menampilkan {index?.total} data</p>
                                    :
                                    <p className="mt-1">Menampilkan 0 data</p>
                            }
                        </div>
                        <div className="col-lg-4 order-0 order-md-1">
                            <SearchTable onSearch={onSearch} onChange={onChangeSearch} value={searchValue} />
                        </div>
                    </div>

                    <div className="table-responsive grid-view mt-3">
                        <table className="table table-bordered">
                            <HeadTable
                                colorized={false}
                                sort={sort}
                                sortData={sortData}
                                columns={[
                                    { type: "label", label: "No." },
                                    { type: "sortable", label: "Nama", field: "nama" },
                                ]}
                            />
                            <tbody className="table-border-bottom-0">
                                {
                                    index?.list?.length > 0 ?
                                        index?.list?.map((val, key) => {
                                            return <tr key={key}>
                                                <td><strong>{((page - 1) * perPage) + key + 1}</strong></td>
                                                <td>{val}</td>
                                            </tr>
                                        })
                                        : <tr>
                                            <td colSpan={7}>Data tidak ditemukan</td>
                                        </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="card-footer text-muted text-center border-top">
                    ©2023 - Seleksi Examinatios
                </div>
            </div>
        </div>
    </React.Fragment>
}

export default HasilSeleksi