import actionTypes from './actionTypes'

export const actionFetchPesertaUjian = (data) => {
  return {
    type: actionTypes.FETCH_PESERTA_UJIAN,
    data
  }
}

export const actionFetchPesertaUjianStart = () => {
  return {
    type: actionTypes.FETCH_PESERTA_UJIAN_START
  }
}

export const actionFetchPesertaUjianEnd = () => {
  return {
    type: actionTypes.FETCH_PESERTA_UJIAN_END
  }
}

export const actionFetchPesertaUjianSuccess = (data) => {
  return {
    type: actionTypes.FETCH_PESERTA_UJIAN_SUCCESS,
    data
  }
}