import React, { useEffect, useState } from 'react'
import ScrollToTop from '../../ScrollToTop'
import UseScript from '../../UseSript'
import SvgLogo from "../../../assets/img/logo.png"
import { APP_NAME } from '../../../constants'
import { Link, useLocation } from "react-router-dom"

const LandingLayout = (props) => {
    const { children } = props
    const [arrLocation, setArrLocation] = useState(null)

    const location = useLocation();

    useEffect(() => {
        let arrLocation = location?.pathname?.split("/")

        if (arrLocation?.length > 0) {
            setArrLocation(arrLocation)
        }
    }, [location])

    return <React.Fragment>
        <ScrollToTop />

        <div className="layout-wrapper layout-content-navbar  layout-without-menu">
            <div className="layout-container">
                <div className="layout-page">
                    <nav className="layout-navbar container-xxl navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme" style={{
                        width: "100%",
                        maxWidth: "1200px"
                    }}
                        id="layout-navbar">
                        <div className="navbar-nav-right d-flex align-items-center" id="navbar-collapse">
                            <div className="navbar-nav align-items-center">
                                <div className="nav-item navbar-search-wrapper mb-0">
                                    <div className="nav-item mb-0 d-block">
                                        <Link to={process.env.REACT_APP_SUBDIR + "/"}>
                                            <div className="app-brand">
                                                <span className="app-brand-logo demo">
                                                    <img src={SvgLogo} alt="Logo" className="logo-app" />
                                                </span>
                                                <span className="app-brand-text demo menu-text fw-bolder ms-2 main-logo-text text-primary d-none d-md-block">{APP_NAME}</span>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>

                            <ul className="navbar-nav flex-row align-items-center ms-auto custom-active">
                                <li className={"nav-item dropdown-shortcuts navbar-dropdown dropdown" + (arrLocation?.length > 0 && arrLocation[1]?.toLowerCase() === "hasil-seleksi" ? " active" : "")} >
                                    <Link className="nav-link fw-bold text-primary" to={process.env.REACT_APP_SUBDIR + "/hasil-seleksi"}>
                                        <small className="text-primary text-uppercase">Hasil Seleksi</small>
                                    </Link>
                                </li>
                                <li className="nav-item dropdown-shortcuts navbar-dropdown dropdown ms-3 d-none d-sm-block">
                                    <a className="nav-link fw-bold text-primary" href={process.env.REACT_APP_URL_SELEKSI || "/"}>
                                        <small className="text-primary text-uppercase">Halaman Seleksi</small>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </nav>

                    <div className="content-wrapper">
                        {children}

                        <div className="content-backdrop fade"></div>
                    </div>
                </div>

                <div className="layout-overlay layout-menu-toggle"></div>

                <div className="drag-target"></div>
            </div >
        </div >

        <UseScript src={"/assets/vendor/libs/jquery/jquery.js"} />
        <UseScript src={"/assets/vendor/libs/popper/popper.js"} />
        <UseScript src={"/assets/vendor/js/bootstrap.js"} />
        <UseScript src={"/assets/vendor/libs/perfect-scrollbar/perfect-scrollbar.js"} />

        <UseScript src={"/assets/vendor/libs/bootstrap-datepicker/bootstrap-datepicker.js"} />

        <UseScript src={"/assets/vendor/js/menu.js"} />
        <UseScript src={"/assets/js/main.js"} />
    </React.Fragment >
}


export default LandingLayout