import React from "react"
import SortTable from "../SortTable"

const HeadTable = ({ columns, sort = null, sortData = null, colorized = true }) => {
    return <React.Fragment>
        <thead className={colorized === true ? "table-primary" : ""}>
            <tr>
                {
                    columns?.map((val, key) => {
                        if (val?.type === "label") {
                            return val?.hidden === true ? null : <th className="text-nowrap" key={key} style={val?.label === "No." ? { width: 5 } : val?.width ? { width: val?.width } : null}>{val?.label}</th>
                        } else if (val?.type === "sortable") {
                            return val?.hidden === true ? null : <th className="text-nowrap" key={key}>
                                <a href="/#" onClick={(e) => sortData(val?.field, e)} className={colorized === true ? "text-white" : "text-primary"}>
                                    {val?.label} <SortTable sort={sort} field={val?.field} />
                                </a>
                            </th>
                        }
                    })
                }
            </tr>
        </thead>
    </React.Fragment >
}

export default HeadTable