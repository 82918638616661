import { combineReducers } from "redux"
import { connectRouter } from 'connected-react-router'
import history from '../utils/history'

import pesertaUjian from "./peserta-ujian/reducer"

const rootReducer = combineReducers({
  router: connectRouter(history),
  pesertaUjian
})

export default rootReducer
